import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import {canNavigate} from '@/libs/acl/routeProtection';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils';
import apps from './routes/apps';
import students from './routes/kesiswaan/bukuinduk/students';
import parents from './routes/kesiswaan/bukuinduk/parents';
import prestasi from './routes/kesiswaan/bukuinduk/prestasi';
import mutasi from './routes/kesiswaan/bukuinduk/mutasi';
import importfoto from './routes/kesiswaan/bukuinduk/importfoto';
// eslint-disable-next-line import/extensions

// Nana Add Js
import pegawai from './routes/kepegawaian/datakepegawaian/pegawai';
import penugasan from './routes/kepegawaian/datakepegawaian/penugasan';
import sertifikasi from './routes/kepegawaian/datakepegawaian/sertifikasi';
import importfotopegawai
  from './routes/kepegawaian/datakepegawaian/importfotopegawai';
import importdatapegawai
  from './routes/kepegawaian/datakepegawaian/importdatapegawai';
import kehadiran from './routes/kepegawaian/datakehadiran/kehadiran';
import rekapkehadiran from './routes/kepegawaian/datakehadiran/rekapkehadiran';
import rekappegawai from './routes/kepegawaian/datakehadiran/rekappegawai';
import rekapsemester from './routes/kepegawaian/datakehadiran/rekapsemester';
import pengajuanabsensi
  from './routes/kepegawaian/dataabsensi/pengajuanabsensi';
import kegiatansekolah from './routes/kepegawaian/dataabsensi/kegiatansekolah';
import importdatakehadiran
  from './routes/kepegawaian/datakehadiran/importdatakehadiran';
import pelanggaran from './routes/kesiswaan/konseling/pelanggaran';
import studikasus from './routes/kesiswaan/konseling/studikasus';
import kunjunganrumah from './routes/kesiswaan/konseling/kunjunganrumah';
import strukturosis from './routes/kesiswaan/osis/strukturosis';
import kandidatketua from './routes/kesiswaan/osis/kandidatketua';
import voting from './routes/kesiswaan/osis/voting';
import kelas from './routes/akademik/kelas/kelas';
import kenaikankelas from './routes/akademik/kelas/kenaikankelas';
import generatejadwal from './routes/akademik/jadwal/generatejadwal';
import jadwalmatapelajaran from './routes/akademik/jadwal/jadwalmatapelajaran';
import jadwalmengajar from './routes/akademik/jadwal/jadwalmengajar';
import pembagianjadwal from './routes/akademik/jadwal/pembagianjadwal';
import tugassekolah from './routes/akademik/jadwal/tugassekolah';
import fisikkesehatan from './routes/akademik/nilai/fisikkesehatan';
import harian from './routes/akademik/nilai/harian';
import keterampilan from './routes/akademik/nilai/keterampilan';
import sikap from './routes/akademik/nilai/sikap';
import ujian from './routes/akademik/nilai/ujian';
import kdspiritual from './routes/akademik/kompetensi/kdspiritual';
import kdsosial from './routes/akademik/kompetensi/kdsosial';
import kdpengetahuan from './routes/akademik/kompetensi/kdpengetahuan';
import kdketerampilan from './routes/akademik/kompetensi/kdketerampilan';
import pmmasterdata from './routes/akademik/perangkatmengajar/pmmasterdata';
import programguru from './routes/akademik/perangkatmengajar/programguru';
import rppguru from './routes/akademik/perangkatmengajar/rppguru';
import matapelajaran from './routes/akademik/matapelajaran/matapelajaran';
import detailmapel from './routes/akademik/matapelajaran/detailmapel';
import jambelajar from './routes/akademik/matapelajaran/jambelajar';
import tipejambelajar from './routes/akademik/matapelajaran/tipejam';
import permintaanmengajar
  from './routes/akademik/matapelajaran/permintaanmengajar';
import transfersiswa from './routes/akademik/kelas/transfersiswa';
import agendamengajar from './routes/akademik/agenda/agendamengajar';
import absensibelajar from './routes/akademik/agenda/absensibelajar';
import agendajadwal from './routes/akademik/agenda/agendajadwal';
import agendatugassekolah from './routes/akademik/agenda/agendatugassekolah';

import nilaiakhir from './routes/akademik/raport/nilaiakhir';
import nilaimapel from './routes/akademik/raport/nilaimapel';
import raport from './routes/akademik/raport/raport';
import saran from './routes/akademik/raport/saran';

import kalenderakademik
  from './routes/akademik/kalenderakademik/kalenderakademik';
import cetakkalender from './routes/akademik/kalenderakademik/cetakkalender';

import absensidatakehadiran
  from './routes/absensi/absensi-kehadiran/datakehadiran';
import absensirekapbulanan
  from './routes/absensi/absensi-kehadiran/rekapbulanan';
import absensirekapkehadiran
  from './routes/absensi/absensi-kehadiran/rekapkehadiran';
import absensiuploaddata from './routes/absensi/absensi-kehadiran/uploaddata';

import izinpengajuanizin from './routes/absensi/absensi-izin/pengajuanizin';
import rekapizinsiswa from './routes/absensi/absensi-izin/rekapizinsiswa';
import rekapizinkelas from './routes/absensi/absensi-izin/rekapizinkelas';
import rekapizinbelajar from './routes/absensi/absensi-izin/rekapizinbelajar';

import pbabsenbelajar from './routes/absensi/presensi-belajar/pbabsenbelajar';
import pbpersentasekbm from './routes/absensi/presensi-belajar/pbpersentasekbm';
import pbrekapmapel from './routes/absensi/presensi-belajar/pbrekapmapel';
import pbuploaddata from './routes/absensi/presensi-belajar/pbuploaddata';

import peabsenekskul from './routes/absensi/presensi-ekskul/peabsenekskul';
import pepresentaseekskul
  from './routes/absensi/presensi-ekskul/pepresentaseekskul';
import perekapekskul from './routes/absensi/presensi-ekskul/perekapekskul';

import importdata from './routes/kesiswaan/bukuinduk/importdata';
import ekstrakurikuler
  from './routes/kesiswaan/ekstrakurikuler/ekstrakurikuler';
import pesertaekskul from './routes/kesiswaan/ekstrakurikuler/pesertaekskul';
import absensiekskul from './routes/kesiswaan/ekstrakurikuler/absensiekskul';
import instrukturekskul
  from './routes/kesiswaan/ekstrakurikuler/instrukturekskul';
import dashboard from './routes/dashboard';
import uiElements from './routes/ui-elements/index';
import pages from './routes/pages';
import chartsMaps from './routes/charts-maps';
import formsTable from './routes/forms-tables';
import others from './routes/others';
import kegiatansekolahsiswa from './routes/kesiswaan/kegiatansekolahsiswa';

import anggota from './routes/perpustakaan/anggota';
import buku from './routes/perpustakaan/buku';
import kartu from './routes/perpustakaan/kartu';
import perpuslaporan from './routes/perpustakaan/laporan';
import peminjaman from './routes/perpustakaan/peminjaman';
import pengembalian from './routes/perpustakaan/pengembalian';

import asset from './routes/inventaris/asset';
import assetkeluar from './routes/inventaris/assetkeluar';
import assetmasuk from './routes/inventaris/assetmasuk';
import assetpemeliharaan from './routes/inventaris/assetpemeliharaan';
import assetpeminjaman from './routes/inventaris/assetpeminjaman';
import assetpengembalian from './routes/inventaris/assetpengembalian';
import barangkeluar from './routes/inventaris/barangkeluar';
import barangmasuk from './routes/inventaris/barangmasuk';
import datainventaris from './routes/inventaris/datainventaris';
import settingumumjenis from './routes/inventaris/settingumumjenis';
import settingumumkatalog from './routes/inventaris/settingumumkatalog';
import settingumumkategori from './routes/inventaris/settingumumkategori';
import settingumumkode from './routes/inventaris/settingumumkode';
import settingumumkondisi from './routes/inventaris/settingumumkondisi';
import settingumumsatuan from './routes/inventaris/settingumumsatuan';
import settingumumsatuanpenyimpanan
  from './routes/inventaris/settingumumsatuanpenyimpanan';
import settingumumstatus from './routes/inventaris/settingumumstatus';

import arsipkeluar from './routes/surat/arsipkeluar';
import arsipmasuk from './routes/surat/arsipmasuk';
import generate from './routes/surat/generate';
import mastersurat from './routes/surat/mastersurat';
import suratkeluar from './routes/surat/suratkeluar';
import suratmasuk from './routes/surat/suratmasuk';
import kategorisurat from './routes/surat/kategorisurat';

import dashboardinformasi from './routes/informasi/dashboardinformasi';
import manajemeninformasi from './routes/informasi/manajemeninformasi';

//keuangan
import laporanpembayaran from './routes/keuangan/laporanpembayaran';
import laporantabungan from './routes/keuangan/laporantabungan';
import masterkeuangan from './routes/keuangan/masterkeuangan';
import masterpotongan from './routes/keuangan/masterpotongan';
import pembayaranbimbel from './routes/keuangan/pembayaranbimbel';
import pembayaranlainnya from './routes/keuangan/pembayaranlainnya';
import pembayaranspp from './routes/keuangan/pembayaranspp';
import pembayaranujian from './routes/keuangan/pembayaranujian';
import tabungan from './routes/keuangan/tabungan';
import generatespp from './routes/keuangan/generatespp';
import paymenttype from './routes/keuangan/paymenttype';
import settingpembayaran from './routes/keuangan/settingpembayaran';

//penggajian
import bpjs from './routes/penggajian/bpjs';
import datareferensi from './routes/penggajian/datareferensi';
import gajitetap from './routes/penggajian/gajitetap';
import gajitidaktetap from './routes/penggajian/gajitidaktetap';
import laporanbanktransfer from './routes/penggajian/laporanbanktransfer';
import laporanrekapgaji from './routes/penggajian/laporanrekapgaji';
import payroll from './routes/penggajian/payroll';
import slipgaji from './routes/penggajian/slipgaji';
import transaksidatareferensi from './routes/penggajian/transaksidatareferensi';
import transaksiuploaddata from './routes/penggajian/transaksiuploaddata';

//Menu
import menu from './routes/menu/menu';
import role from './routes/role/role';
import rolemenu from './routes/role/rolemenu';
import roleusers from './routes/role/usersrole';

//users
import users from './routes/users/users';

import calonsiswa from './routes/ppdb/datacalonsiswa';
import jadwaltest from './routes/ppdb/datajadwaltest';
import datapembayaran from './routes/ppdb/datapembayaran';

import $ from 'jquery';

Vue.use (VueRouter);

const router = new VueRouter ({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {x: 0, y: 0};
  },
  routes: [
    {path: '/', redirect: {name: 'dashboard-ecommerce'}},
    ...students,
    ...parents,
    ...prestasi,
    ...mutasi,
    ...importfoto,
    ...importdata,
    ...ekstrakurikuler,
    ...pesertaekskul,
    ...absensiekskul,
    ...instrukturekskul,
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...pegawai,
    ...penugasan,
    ...sertifikasi,
    ...importfotopegawai,
    ...importdatapegawai,
    ...kehadiran,
    ...rekapkehadiran,
    ...rekappegawai,
    ...rekapsemester,
    ...pengajuanabsensi,
    ...kegiatansekolah,
    ...importdatakehadiran,
    ...pelanggaran,
    ...studikasus,
    ...kunjunganrumah,
    ...strukturosis,
    ...kandidatketua,
    ...voting,
    ...kegiatansekolahsiswa,
    ...kelas,
    ...kenaikankelas,
    ...generatejadwal,
    ...jadwalmatapelajaran,
    ...jadwalmengajar,
    ...pembagianjadwal,
    ...tugassekolah,
    ...fisikkesehatan,
    ...harian,
    ...keterampilan,
    ...sikap,
    ...ujian,
    ...kdspiritual,
    ...kdsosial,
    ...kdpengetahuan,
    ...kdketerampilan,
    ...pmmasterdata,
    ...programguru,
    ...rppguru,
    ...matapelajaran,
    ...detailmapel,
    ...jambelajar,
    ...tipejambelajar,
    ...permintaanmengajar,
    ...transfersiswa,
    ...agendamengajar,
    ...absensibelajar,
    ...agendajadwal,
    ...agendatugassekolah,
    ...absensidatakehadiran,
    ...absensirekapbulanan,
    ...absensirekapkehadiran,
    ...absensiuploaddata,
    ...izinpengajuanizin,
    ...rekapizinsiswa,
    ...rekapizinkelas,
    ...rekapizinbelajar,
    ...pbabsenbelajar,
    ...pbpersentasekbm,
    ...pbrekapmapel,
    ...pbuploaddata,
    ...peabsenekskul,
    ...pepresentaseekskul,
    ...perekapekskul,
    ...nilaiakhir,
    ...nilaimapel,
    ...raport,
    ...saran,
    ...kalenderakademik,
    ...cetakkalender,
    ...anggota,
    ...buku,
    ...kartu,
    ...perpuslaporan,
    ...peminjaman,
    ...pengembalian,
    ...asset,
    ...assetkeluar,
    ...assetmasuk,
    ...assetpemeliharaan,
    ...assetpeminjaman,
    ...assetpengembalian,
    ...barangkeluar,
    ...barangmasuk,
    ...datainventaris,
    ...settingumumjenis,
    ...settingumumkatalog,
    ...settingumumkategori,
    ...settingumumkode,
    ...settingumumkondisi,
    ...settingumumsatuan,
    ...settingumumsatuanpenyimpanan,
    ...settingumumstatus,
    ...dashboardinformasi,
    ...manajemeninformasi,
    ...arsipkeluar,
    ...arsipmasuk,
    ...generate,
    ...mastersurat,
    ...suratkeluar,
    ...suratmasuk,
    ...laporanpembayaran,
    ...laporantabungan,
    ...masterkeuangan,
    ...masterpotongan,
    ...pembayaranbimbel,
    ...pembayaranlainnya,
    ...pembayaranspp,
    ...pembayaranujian,
    ...tabungan,
    ...generatespp,
    ...bpjs,
    ...datareferensi,
    ...gajitetap,
    ...gajitidaktetap,
    ...laporanbanktransfer,
    ...laporanrekapgaji,
    ...payroll,
    ...slipgaji,
    ...transaksidatareferensi,
    ...transaksiuploaddata,
    ...menu,
    ...role,
    ...rolemenu,
    ...roleusers,
    ...users,
    ...kategorisurat,
    ...calonsiswa,
    ...jadwaltest,
    ...datapembayaran,
    ...paymenttype,
    ...settingpembayaran,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach ((to, _, next) => {
  const isLoggedIn = isUserLoggedIn ();
  // console.log ('Cek User Login');
  // console.log (isLoggedIn);

  if (!canNavigate (to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) 
    return next ({name: 'auth-login'});    

    // If logged in => not authorized
    return next ({name: 'misc-not-authorized'});
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData ();
    //next (getHomeRouteForLoggedInUser (userData ? userData.role : null));
  }  
  return next ();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach (() => {
  // Remove initial loading
  const appLoading = document.getElementById ('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
